import React, { Component } from 'react'
import PropTypes from 'prop-types'

import DateTimeInput from './DateTimeInput'

import moment from 'ct-moment'
import 'moment-timezone'

class ControlledDateTimeInput extends Component {
  constructor(props) {
    super(props)

    const initialDate = props.value
      ? moment(props.value, 'YYYY-MM-DD HH:mm:SS Z').tz(props.timeZone)
      : moment.tz(props.timeZone)
    initialDate.minutes((Math.ceil(initialDate.minutes() / 5) * 5) % 60)

    this.state = {
      value: initialDate.format(),
    }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange({ target: { value } }) {
    this.setState({ value })
  }

  render() {
    const { id, name, timeZone } = this.props
    return (
      <div>
        <input type="hidden" name={name} value={this.state.value} />
        <DateTimeInput
          id={id}
          name={name}
          value={this.state.value}
          onChange={this.handleChange}
          timeZone={timeZone}
        />
      </div>
    )
  }
}

ControlledDateTimeInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  timeZone: PropTypes.string.isRequired,
  value: PropTypes.string,
}

export default ControlledDateTimeInput
