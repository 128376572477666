import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'
import moment from 'ct-moment'
import Datetime from 'react-datetime'

class DOBPicker extends Component {
  constructor(props) {
    super(props)

    this.renderInput = this.renderInput.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSelectedDate = this.handleSelectedDate.bind(this)
    this.pad = this.pad.bind(this)
  }

  handleChange({ target: { name, value } }) {
    const convertedDate = this.props.value.split('-')
    switch (name) {
      case 'year':
        convertedDate[0] = value
        break
      case 'month':
        convertedDate[1] = value
        break
      case 'day':
        convertedDate[2] = value
        break
    }
    this.props.onChange({ target: { name: this.props.name, value: convertedDate.join('-') } })
  }

  pad() {
    const convertedDate = this.props.value.split('-')
    convertedDate[1] = convertedDate[1] ? convertedDate[1].padStart(2, '0') : ''
    convertedDate[2] = convertedDate[2] ? convertedDate[2].padStart(2, '0') : ''

    this.props.onChange({ target: { name: this.props.name, value: convertedDate.join('-') } })
  }

  handleSelectedDate(selectedDate) {
    this.props.onChange({ target: { name: this.props.name, value: selectedDate.format('YYYY-MM-DD') } })
  }

  renderInput({ onFocus, value: date }) {
    const convertedDate = date.split('-')

    const yearNum = convertedDate[0]
    const monthNum = convertedDate[1]
    const dayNum = convertedDate[2]

    return (
      <div id="dob-input" className="form__row" onFocus={onFocus}>
        <div className="placeholder-text" data-placeholder={t('yyyy', { scope: 'care_plan_creators.new' })}>
          <input
            name="year"
            onChange={this.handleChange}
            value={yearNum}
            type="number"
            min={1900}
            max={moment().year()}
            autoComplete="off"
            disabled={this.props.disabled}
          />
        </div>
        <div className="placeholder-text" data-placeholder={t('mm', { scope: 'care_plan_creators.new' })}>
          <input
            name="month"
            onChange={this.handleChange}
            onBlur={this.pad}
            value={monthNum}
            type="number"
            min={1}
            max={12}
            autoComplete="off"
            disabled={this.props.disabled}
          />
        </div>
        <div className="placeholder-text" data-placeholder={t('dd', { scope: 'care_plan_creators.new' })}>
          <input
            name="day"
            onChange={this.handleChange}
            onBlur={this.pad}
            value={dayNum}
            type="number"
            min={1}
            max={31}
            autoComplete="off"
            disabled={this.props.disabled}
          />
        </div>
      </div>
    )
  }

  render() {
    return (
      <Datetime
        value={this.props.value}
        closeOnSelect={false}
        dateFormat="YYYY-MM-DD"
        timeFormat={false}
        renderInput={this.renderInput}
        onChange={this.handleSelectedDate}
      />
    )
  }
}

DOBPicker.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}

DOBPicker.defaultProps = {
  value: '',
  disabled: false,
}

export default DOBPicker
