import React from 'react'
import PropTypes from 'prop-types'
import symbolDefs from 'symbol-defs.svg'

const Icon = ({ className, name, title }) => {
  const classes = `icon ${className}`

  return (
    <svg className={classes}>
      <title>{title}</title>
      <use href={`${symbolDefs}#icon_${name}`}></use>
    </svg>
  )
}

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
}

Icon.defaultProps = {
  className: '',
  title: '',
}

export default Icon
