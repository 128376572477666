export const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB

export const ACCEPTED_MEDICAL_DOCUMENT_TYPES = [
  'application/msword',
  'application/octet-stream',
  'application/pdf',
  'application/rtf',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.template',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'image/*',
  'text/csv',
  'text/plain',
];

export const MIXPANEL_EVENTS = {
  clicked_add_team_members_button: 'Clicked add team members button',
};
