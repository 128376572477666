// elsewhere, moment must be imported
// using ct-moment (alias of localizedMoment) to ensure
// that the proper localization context is included

// eslint-disable-next-line
import moment from 'moment'
// eslint-disable-next-line
import I18n from 'i18n'

function localizeMoment() {
  moment.locale(I18n.currentLocale())
  return moment
}

const localizedMoment = localizeMoment()

export default localizedMoment
export { localizeMoment }
