import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'
import Datetime from 'react-datetime'
import { debounce } from 'lodash'
import { BrowserView, MobileView, isBrowser } from 'react-device-detect'
import Select from './Select'

import moment from 'ct-moment'
import 'moment-timezone'

const i18nOpts = { scope: 'forms.date_time_input' }

class DateTimeInput extends Component {
  constructor(props) {
    super(props)

    this.handleDatePickerVisibility = this.handleDatePickerVisibility.bind(this)
    this.determineScrollRequirement = this.determineScrollRequirement.bind(this)
    this.adjustDatePickerPosition = debounce(this.adjustDatePickerPosition.bind(this), 25, { maxWait: 75 })

    this.handleDatePickerChange = this.handleDatePickerChange.bind(this)
    this.handlePeriodChange = this.handlePeriodChange.bind(this)
    this.handleTimeChange = this.handleTimeChange.bind(this)
  }

  componentWillUnmount() {
    if (!this.props.ensureVisibility) {
      return
    }

    window.removeEventListener('resize', this.adjustDatePickerPosition)

    const modalWindow = document.querySelector('.ReactModal__Content')
    if (modalWindow !== null) {
      modalWindow.removeEventListener('scroll', this.adjustDatePickerPosition)
    }
  }

  handleDatePickerVisibility() {
    if (!this.props.ensureVisibility) {
      return
    }
    this.determineScrollRequirement()
    this.adjustDatePickerPosition()
  }

  determineScrollRequirement() {
    if (!this.props.ensureVisibility) {
      return
    }
    const modalWindow = document.querySelector('.ReactModal__Content')

    if (modalWindow !== null && modalWindow.querySelector('.date') !== null) {
      // if a modal window is present and a datepicker element is contained within it, we need:
      //  1. a scroll listener to adjust the position of the datepicker if the modal body scrolls;
      //  2. a resize listener to adjust the position of the datepicker if the viewport changes size.

      modalWindow.addEventListener('scroll', this.adjustDatePickerPosition)
      window.addEventListener('resize', this.adjustDatePickerPosition)
    }
  }

  adjustDatePickerPosition() {
    if (!this.props.ensureVisibility) {
      return
    }

    const datePickerElement = document.querySelector('.rdtPicker')
    const datePickerBottom = this.datePickerElementBounding.height + this.dateElementBounding.bottom
    const datePickerWouldBeHidden =
      datePickerBottom >= (window.innerHeight || document.documentElement.clientHeight)

    if (datePickerWouldBeHidden) {
      datePickerElement.setAttribute(
        'style',
        `top: ${this.dateElementBounding.top - this.datePickerElementBounding.height}px`
      )
    } else {
      datePickerElement.removeAttribute('style')
    }

    if (this.props.renderAbove) {
      let datePickerElement
      const parentElement = document.querySelector('.rdtOpen')
      for (const element of parentElement.childNodes) {
        if (element.classList.contains('rdtPicker')) {
          datePickerElement = element
        }
      }

      parentElement.setAttribute('style', `position: relative`)
      datePickerElement.setAttribute('style', 'position: absolute')
      datePickerElement.setAttribute('style', `bottom: ${this.dateElementBounding.height}px`)
    }
  }

  get dateElementBounding() {
    return document.querySelector('.date').getBoundingClientRect()
  }

  get datePickerElementBounding() {
    return document.querySelector('.rdtPicker').getBoundingClientRect()
  }

  range(start, end, step = 1) {
    const a = []
    for (let n = start; n <= end; n += step) {
      a.push(n)
    }
    return a
  }

  handleDatePickerChange(evt) {
    // Convert evt._d (DATE) into props.value compatible ISO string
    const dateTime = this.dateTime

    if (isBrowser) {
      dateTime._d = evt._d
    } else {
      // Date in in YYYY-MM-DD format
      const [year, month, day] = evt.target.value.split('-')

      dateTime.year(year)
      dateTime.month(month - 1) // month is zero-indexed
      dateTime.date(day)
    }

    try {
      return this.props.onChange({ target: { name: this.props.name, value: dateTime.format() } })
    } catch (error) {
      console.error('invalid date format entered')
    }
  }

  handleTimeChange(name, dateTime, { target }) {
    // Convert target.value (Int) into props.value compatible ISO string
    dateTime[target.name](target.value)
    return this.props.onChange({ target: { name, value: dateTime.format() } })
  }

  handlePeriodChange(name, dateTime, { target: { value } }) {
    const currentHour = dateTime.hour()
    const newHour = value === 'am' ? currentHour - 12 : currentHour + 12
    dateTime.hour(newHour)
    return this.props.onChange({ target: { name, value: dateTime.format() } })
  }

  get dateTime() {
    const { value, timeZone } = this.props

    return value ? moment.tz(value, timeZone) : moment({ hour: 0, minute: 0 }).tz(timeZone)
  }

  get hour() {
    return this.dateTime.hour()
  }

  get minute() {
    return (Math.ceil(this.dateTime.minutes() / 5) * 5) % 60
  }

  get period() {
    return this.dateTime.hour() >= 12 ? 'pm' : 'am'
  }

  render() {
    const { value, name, withDate, withTime, ensureVisibility, id } = this.props

    return (
      <div
        className="datetime"
        onClick={
          // Prevents clicking inside this component from triggering an onClick event in the parent which
          // could force this component to re-render (with the datepicker closed)
          (evt) => evt.stopPropagation()
        }
      >
        {withDate && (
          <div className="date">
            <BrowserView>
              <Datetime
                className={ensureVisibility ? 'rdt--fixed' : ''}
                input
                closeOnSelect
                value={this.dateTime.format('MM/DD/YYYY')}
                timeFormat={false}
                onChange={this.handleDatePickerChange}
                onFocus={this.handleDatePickerVisibility}
                onViewModeChange={this.adjustDatePickerPosition}
                inputProps={{ id, className: 'string' }}
              />
            </BrowserView>
            <MobileView>
              <input
                value={moment(this.dateTime).format('YYYY-MM-DD')}
                type="date"
                onChange={this.handleDatePickerChange}
              />
            </MobileView>
          </div>
        )}
        {withTime && (
          <div className="time">
            <Hours
              options={[12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]}
              isPM={this.period === 'pm'}
              value={value ? this.hour : -1}
              onChange={this.handleTimeChange.bind(null, name, this.dateTime)}
            />
            <Minutes
              options={this.range(0, 59, 5)}
              value={value ? this.minute : -1}
              onChange={this.handleTimeChange.bind(null, name, this.dateTime)}
            />
            <Period value={this.period} onChange={this.handlePeriodChange.bind(null, name, this.dateTime)} />
          </div>
        )}
      </div>
    )
  }
}

const Hours = ({ options, value, isPM, onChange }) => (
  <Select name="hour" value={value} onChange={onChange}>
    <option value={-1} disabled />
    {options.map((display, i) => (
      <option key={i} value={isPM ? i + 12 : i}>
        {display}
      </option>
    ))}
  </Select>
)

const Minutes = ({ options, value, onChange }) => (
  <Select name="minute" value={value} onChange={onChange}>
    <option value={-1} disabled />
    {options.map((value, i) => (
      <option key={i} value={value}>
        :{`0${value}`.slice(-2)}
      </option>
    ))}
  </Select>
)

const Period = ({ value, onChange }) => (
  <Select name="period" value={value} onChange={onChange}>
    <option key={1} value="am">
      {t('am', i18nOpts)}
    </option>
    <option key={2} value="pm">
      {t('pm', i18nOpts)}
    </option>
  </Select>
)

Hours.propTypes = {
  isPM: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.number.isRequired,
}

Minutes.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.number.isRequired,
}

Period.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

DateTimeInput.propTypes = {
  ensureVisibility: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  renderAbove: PropTypes.bool,
  timeZone: PropTypes.string.isRequired,
  value: PropTypes.string,
  withDate: PropTypes.bool,
  withTime: PropTypes.bool,
}

DateTimeInput.defaultProps = {
  ensureVisibility: false,
  errors: {},
  id: null,
  renderAbove: false,
  value: null,
  withDate: true,
  withTime: true,
}

export default DateTimeInput
