class Utilities {
  constructor() {}

  queryCTSelector(selector, node = document) {
    return node.querySelector(
      `[ct-${selector}]:not([ct-${selector}=false]), [data-ct-${selector}]:not([data-ct-${selector}=false])`
    )
  }

  queryCTSelectorAll(selector, node = document) {
    return node.querySelectorAll(
      `[ct-${selector}]:not([ct-${selector}=false]), [data-ct-${selector}]:not([data-ct-${selector}=false])`
    )
  }

  clearFormValues(form) {
    // Add additional form input types here.
    for (const ele of form.querySelectorAll('input, textarea')) {
      ele.value = ''
      ele.innerHTML = ''
      ele.setAttribute('value', '')
    }
    return form
  }

  cleanAttributes(ele) {
    if (!ele.getAttributeNames) return ele
    const dataAttribute = /data-(.*)/
    ele.getAttributeNames().forEach((name) => {
      const matches = name.match(dataAttribute)
      if (matches) {
        ele.setAttribute(matches[1], ele.getAttribute(name))
      }
    })
    return ele
  }
}

export const onAssignmentChange = (onChange) => (assignments, { action, removedValue }) => {
  switch (action) {
    case 'remove-value':
    case 'pop-value':
      if (removedValue.readOnly) {
        return
      }
      break
  }

  if (assignments === null) {
    assignments = []
  }

  onChange({ target: { name: 'assignments', value: assignments } })
}

export default Utilities
