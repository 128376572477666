import React from 'react'

const Select = ({ name, value, onChange, children, disabled }) => {
  return (
    <select name={name} id={name} value={value} onChange={onChange} disabled={disabled}>
      {children}
    </select>
  )
}

export default Select
